// import $ from 'jquery';//
import Module from 'lib/module';
import Util from 'lib/util';

/**
 * @class Common
 * @extends {Module}
 */
@Module.define('common')
class Common extends Module {

	constructor(options) {
		super(options);

		this.searchToggleElem = $('.js-search-toggle');
		this.searchAgain = $('.js-search-again');
		this.searchSort = $('.js-sort-options');
		this.searchSortSelect = $('.js-sort-options select');
        // this.urlParams = new URLSearchParams(window.location.search);
        // IE 11 compatable URLSearchParams fix :(
        var urlSearchParam = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results === null) {
                return null;
            }
            else {
                return decodeURI(results[1]) || 0;
            }
        };
        this.urlParams = urlSearchParam('search');
        if (this.urlParams !== null) {
					this.searchSortValue = urlSearchParam(window.searchSortKey);
					this.searchFilterValue = urlSearchParam(window.searchFilterKey);
        }
	}


	init() {
		// sub-modules must emit an `init` event
		return super.init().then(e => this.emit('init', e));
	}

	main() {
		this.setSortSelect();

		this.navDropdown();

		if (Util.checkWidth() === "mobile" || Util.checkWidth() === "tablet" || Util.checkWidth() === "desktopNarrow") {
			this.navToggles();
		}

	}

    /**
     * @listens window#load
     * @listens window#resize
     * @memberof Common
     */
	bindEvents() {
		super.bindEvents();

		this.searchToggleElem.on('click', e => this.searchToggle());
		this.searchAgain.on('click', e => this.searchToggle());
		this.searchSortSelect.on('change', e => this.sortSearchResults());

		$(window).on('resize', Util.debounce(e => this.onResize(e)));
	}


    /**
     * Called when the window is resized (after being debounced)
     * 
     * @param {ResizeEvent} e - the event
     * @memberof Common
     */
	onResize(e) {

		if (Util.checkWidth() === 'desktop') {
			// Items
		}

		if (Util.checkWidth() === "mobile" || Util.checkWidth() === "tablet" || Util.checkWidth() === "desktopNarrow") {
			this.navToggles();

		}
	}

	// Search Input Toggle
	searchToggle() {
		const $searchInput = $('.js-search-input-container');

		$searchInput.toggleClass('hide-desktop-wide');
		$searchInput.find('input[type=text]').focus();
	}

	// Sort Search
	sortSearchResults() {
		this.searchSort.trigger('submit');
	}

	setSortSelect() {
		$('#' + window.searchSortKey).find('option[value=' + this.searchSortValue + ']').prop('selected', true);
		$('#' + window.searchFilterKey).find('option[value=' + this.searchFilterValue + ']').prop('selected', true);
	}

	// Mobile Main Nav
	mainNavToggle() {
		const $navToggle = $('.js-navbar-toggle');
		const $mobileNav = $('.js-navbar-collapse');
		const $dropdownParent = $('.js-nav-item-parent');
		const $navItemDropdown = $('.nav-item-dropdown');
		let utilityBarHeight = $('.js-utility-bar').outerHeight();
		let statusBarHeight = $('.js-status-bar').outerHeight();
		let navbarHeaderHeight = $('.js-navbar-header').outerHeight();
		let windowHeight = $(window).height();
		let navMaxHeight = windowHeight - (utilityBarHeight + statusBarHeight + navbarHeaderHeight);


		$navToggle.unbind('click').on('click', function () {
			$dropdownParent.removeClass('open');
			$navItemDropdown.slideUp();

			$('.js-navbar-collapse').css('max-height', navMaxHeight);

			$mobileNav.slideToggle();
		});
	}

	// Mobile Sidebar & Footer
	toggleNextElem() {
		const $nextElemToggle = $('.js-toggle-next-el');

		$nextElemToggle.off('click').on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('open');
			$(this.nextElementSibling).toggleClass('open');
		});
	}

	navDropdown() {
		const $dropdownParent = $('.js-nav-item-parent');
        const $navDropdown = $('.nav-item-dropdown');

		$dropdownParent.off('click touch').on('click touch', function (e) {
			let $target = e.currentTarget;

			if ($target.classList.contains('open')) {
				$target.classList.remove('open');

				if (Util.checkWidth() === 'desktopNarrow' || Util.checkWidth() === 'desktopWide') {
					$(this).find('.nav-item-dropdown').hide();
				}
				else {
					$navDropdown.slideUp();
					$(this).find('.nav-item-dropdown').slideUp();
				}
			}
			else {
				$dropdownParent.removeClass('open');

				if (Util.checkWidth() === 'desktopNarrow' || Util.checkWidth() === 'desktopWide') {
					$navDropdown.hide();
					$(this).find('.nav-item-dropdown').show();
				}
				else {
					$navDropdown.slideUp();
					$(this).find('.nav-item-dropdown').slideDown();
				}

				$target.classList.add('open');
			}
        });

        // close dropdown if click event outside of dropdown
        $(window).on('click touch', function(e) {
            let $target = $(e.target);
            if (!$target.parents('.js-nav-item-parent').length && $navDropdown.is(':visible')) {
                $navDropdown.hide();
                $('.js-nav').find('.open').removeClass('open');
            }
        });
	}

	navToggles() {
		this.mainNavToggle();
		this.toggleNextElem();
	}


}