// check for jquery
if ('undefined' === typeof $) throw new ReferenceError('jQuery ($) is undefined. Please load jquery before your bundle.')
//
/**
 * Static class of utility functions, and the like.
 * 
 * @namespace Util
 */
class Util {
    /**
     * Check the viewport type, based on the specified breakpoints
     * 
     * @deprecated Use Util.getViewPort() instead.
     * @static
     * @param {number} [desktopWideBreakpoint=1170] desktop
     * @param {number} [desktopNarrowBreakpoint=970] narrow
     * @param {number} [tabletBreakpoint=750] tablet
     * @returns {ViewPort} The viewport (desktop|mobile|tablet)
     * @memberof Util
     */
	static checkWidth(desktopWideBreakpoint, desktopNarrowBreakpoint, tabletBreakpoint) {
        // Util.deprecate('Util.checkWidth()', 'Use Util.getViewPort() instead')
        return Util.getViewPort(desktopWideBreakpoint, desktopNarrowBreakpoint, tabletBreakpoint);
    }

    /**
     * Debounce a function
     * 
     * @static
     * @param {function} func func
     * @param {number} [delay=150] delay
     * @param {boolean} [immediate=false] immediate
     * @returns {function} function
     * @memberof Util
     */
    static debounce(func, delay, immediate) {
        delay = delay || 150;
        immediate = immediate === true || false;

        var timeout;
        return function () {
            var context = this;
            var args = arguments;

            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };

            var callNow = immediate && !timeout;
            window.clearTimeout(timeout);
            timeout = window.setTimeout(later, delay);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }

    /**
     * Logs a depreciation notice to the console
     * 
     * @static
     * @param {string|function} method The name of the method, or the method being deprecated
     * @param {string} [message=''] An additional message
     * @memberof Util
     */
    static deprecate(method, message) {
        message = message || '';
        var name = method;

        if ('function' === typeof method) {
            name = method.name;
        }

        console.warn(`[Deprecation] ${name}. ${message}`);
    }

    /**
     * Throws an error if `window.$` or `window.jQuery` is undefined
     * 
     * @static
     * @memberof Util
     * @throws {ReferenceError}
     */
    static ensureJQuery() {
        if ('undefined' === typeof $ || 'undefined' === typeof jQuery) throw new ReferenceError('jQuery ($) is undefined. Please load jQuery before your bundle.');
    }

    /**
     * Set all elements in jquery-compatible selection to the height of the element in the collection with the largest height (within a row)
     * 
     * @static
     * @param {string|JObject} selector selector
     * @memberof Util
     */
    static equalHeight(selector) {
        var currentTallest = 0;
        var currentRowStart = 0;
        var rowDivs = [];

        $(selector).each((i, el) => {
            var elem = $(el);
            elem.outerHeight('auto');
            var topPostion = elem.position().top;
            var currentDiv;

            if (currentRowStart !== topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].outerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = elem.outerHeight();
                rowDivs.push(elem);
            } else {
                rowDivs.push(elem);
                currentTallest = currentTallest < elem.outerHeight() ? elem.outerHeight() : currentTallest;
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].outerHeight(currentTallest);
            }
        });
    }


    static equalHeightAll(selector) {
        var currentTallest = 0;
        var currentRowStart = 0;
        var rowDivs = [];

        $(selector).each((i, el) => {
            var elem = $(el);
            elem.outerHeight('auto');
            var currentDiv;

            rowDivs.push(elem);
            currentTallest = currentTallest < elem.outerHeight() ? elem.outerHeight() : currentTallest;

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].outerHeight(currentTallest);
            }
        });
    }

    static getQueryString(name, url) {
        if (!Util.hasValue(url)) url = window.location.href;

        name = name.replace(/[\[\]]/g, '\\$&');

        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';
        let value = decodeURIComponent(results[2].replace(/\+/g, ' '));
        return value;
    }


	static getViewPort(desktopWideBreakpoint, desktopNarrowBreakpoint, tabletBreakpoint) {
		desktopWideBreakpoint = desktopWideBreakpoint || 1170
        desktopNarrowBreakpoint = desktopNarrowBreakpoint || 992
        tabletBreakpoint = tabletBreakpoint || 621

        var width = window.innerWidth;

		if (width >= desktopWideBreakpoint) {
			return ViewPort.DesktopWide
		}else if (width >= desktopNarrowBreakpoint) {
            return ViewPort.DesktopNarrow;
        } else if (width >= tabletBreakpoint) {
            return ViewPort.Tablet;
        }
        return ViewPort.Mobile;
    }

    /**
     * Determine if `value` is NOT null, or undefined, or if `value` is a string, is NOT empty
     * 
     * @static
     * @param {any} value value
     * @returns {boolean} A boolean indication if `value` is not undefined, null, or an empty string
     * @memberof Util
     */
    static hasValue(value) {
        var hasValue = value !== undefined && value !== null;

        if (hasValue && 'string' === typeof (value)) {
            hasValue = hasValue && value !== "";
        }

        return hasValue;
    }

    /**
     * Trim whitespace from both sides of a string, as well as occurences of the specified character
     * 
     * @static
     * @param {string} str str
     * @param {string} char char
     * @returns {string} The trimmed string
     * @memberof Util
     */
    static trim(str, char) {
        if (str === null || str === undefined) return "";

        let value = str.trim();
        if (Util.hasValue(char)) value = value.replace(new RegExp(`(^${char}|${char}$)`, "g"), "");

        return value;
    }
}

export default Util;


/**
 * @enum {string} ViewPort
 * @memberof Util
 * @example
 * import Util, { ViewPort } from './util';
 * console.log(Util.getViewPort() === ViewPort.Desktop)  // -> true
 */
const ViewPort = {
	DesktopWide: 'desktopWide',
	DesktopNarrow: 'desktopNarrow',
    Mobile: 'mobile',
    Tablet: 'tablet'
};
export { ViewPort };
