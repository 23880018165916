/**
 * This file serves as the entry point to the client-side application logic
 * It bootstraps the Common Module when the page is finshed laoding
 */
//
import Module from 'lib/module';
import Util from 'lib/util';

import 'modules/common';

// make sure jquery exists
Util.ensureJQuery();

$().ready(e => Module.loadAll());